import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AOS from 'aos';
import { Helmet } from 'react-helmet';
import Loader from './Loader';
import Banner from './Banner';
import { useScrollToTop } from './UserScrollTop';

const Blogs = () => {
    const [screenLoading, setScreenLoading] = useState(false);

    useEffect(() => {
        AOS.init({ duration: 1500 });
        AOS.refresh();
        setScreenLoading(false);
        setTimeout(() => {
            setScreenLoading(false);
        }, 3000);
    }, []);
    
    useScrollToTop()
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Astra Surfaces - Blogs</title>
                <meta name="description" content="Uncover trends, tips, and insights on our blog. Stay informed and inspired with our latest posts on countertops. Increase your knowledge today." />
            </Helmet>
            <>
                {screenLoading ? (
                    <Loader />
                ) :
                    <>
                        <Banner name="Blogs" url="assests/images/CALACATTA ARABASCATO 001.webp" />
                        {/* <div className='banner-section'>
                            <img src="assests/images/CALACATTA ARABASCATO 001.webp" alt="banner-img" />
                            <div className='banner-text'>
                                <h1>Blogs</h1>
                            </div>
                        </div> */}
                        <section className='blog-pag gap'>
                            <div className="container">
                                <div className="row gx-0 d-flex justify-content-center mb-5">
                                    <div className="col-lg-10" data-aos="fade-left">
                                        <div className="row blog gx-0">
                                            <div className="col-lg-4">
                                                <div className="image-wrapper">
                                                    <img src="assests/images/Macchia-antica-1-scaled.jpg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-8 my-auto blog-content border">
                                                <h2>Granite Countertops Vs. Quartz Countertops: Which is best?</h2>
                                                <hr />
                                                <p>
                                                    Want to know the difference between granite and quartz countertops? Read this blog to understand granite and quartz countertops, their pros and cons, and understand which one fits your space the best!
                                                </p>
                                                <Link to={"/Granite-Countertops-Vs-Quartz-Countertops:-Which-is-best?"}><button>Read More..</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-10 mt-lg-5" data-aos="fade-right">
                                        <div className="row blog gx-0">
                                            <div className="col-lg-4">
                                                <div className="image-wrapper">
                                                    <img src="assests/images/cristallo-rose-scaled.jpeg" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-8 my-auto blog-content border">
                                                <h2>Top 6 Kitchen Countertop Trends 2024</h2>
                                                <hr />
                                                <p>
                                                    Looking for a countertop upgrade? Discover the top 6 countertop trends of 2024 at Astra Surfaces. Get inspired for your next kitchen renovation project.
                                                </p>
                                                <Link to={"/Top-6-Kitchen-Countertop-Trends-2024"}><button>Read More..</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                }
            </>
        </>
    )
}

export default Blogs