import React, { useEffect, useState } from 'react'
import Loader from './Loader';
import { Helmet } from 'react-helmet';
import { useScrollToTop } from './UserScrollTop';

const Faq = () => {
    const [screenLoading, setScreenLoading] = useState(false);

    useEffect(() => {
        setScreenLoading(false);
        setTimeout(() => {
            setScreenLoading(false);
        }, 3000);
    }, []);

    useScrollToTop()
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Astra Surfaces | FAQ</title>
            </Helmet>
            <>
                {screenLoading ? (
                    <Loader />
                ) :
                    <section id='faq' className='gap-1'>
                        <div className="section-2">
                            <div className="row">
                                <div className="col-lg-8 col-md-10 mx-auto">
                                    <div className="accordion accordion-flush" id="accordionFlushExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    What kind of countertops does Astra Surfaces offer in New Jersey?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>
                                                        As a countertop company in Linden, New Jersey, we offer various countertop surfaces. From natural stone countertops such as granite, marble and quartzite countertops to quartz countertops and porcelain slabs, we offer countertop slabs to suit your unique interior requirements.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                    Where can I install countertops in my home?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>
                                                        You can install countertops in your kitchen, bathroom, outdoor and BBQ areas, and other spaces. You could also be creative and add flair to your dining room, patio, and laundry room with our countertop slabs.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                    Are countertops from Astra Surfaces suitable for commercial spaces?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>
                                                        Yes! Our countertop surfaces are sturdy and suitable for residential and commercial applications. Elevate your cafe, hotel, office, restaurant, bar, and more with our countertops.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                    Do you offer marble countertops?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>
                                                        Indeed. Astra Surfaces offers marble countertops in New Jersey. Visit our showroom to explore wide options regarding the same.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingFive">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                                    Can I get a quartz countertop for my kitchen?
                                                </button>
                                            </h2>
                                            <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <p>
                                                        Our showroom showcases various quartz countertops in numerous designs, patterns, and colors. Visit us, and our team will guide you in choosing the best countertop for your space.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </>
        </>
    )
}

export default Faq