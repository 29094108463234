import React, { useEffect, useState } from "react";
import AOS from "aos";
import { Helmet } from "react-helmet";
import Loader from "./Loader";
import { useScrollToTop } from "./UserScrollTop";
import { Link } from "react-router-dom";
import { useProductContext } from "../context/CategoryContext";
import Banner from "./Banner";

const About = () => {
  const [screenLoading, setScreenLoading] = useState(false);
  const { productsCategory, updateSelectedCategory, fetchProductsByCategory } = useProductContext();
  useEffect(() => {
    AOS.init({ duration: 1500 });
    AOS.refresh();
    setScreenLoading(false);
    setTimeout(() => {
      setScreenLoading(false);
    }, 3000);
  }, []);
  const handleClick = async (category) => {
    await fetchProductsByCategory(category.category_url);
    updateSelectedCategory(category)
  }
  useScrollToTop();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Astra Surfaces | Countertop Company in New Jersey |</title>
        <meta
          name="description"
          content="Curious to know more about Astra Surfaces? Learn about the brand, our values, and what we stand for. We are your reliable countertop company in New Jersey!"
        />
        <meta
          name="keywords"
          content="countertops in New Jersey,countertop industry,countertop distributor in New Jersey,New Jersey showroom,countertop distribution industry,countertop solutions"
        />
      </Helmet>
      <>
        {screenLoading ? (
          <Loader />
        ) : (
          <>
            <div className="about-banner-section">
              <img src="assests/images/banner-2.webp" alt="banner-img" />
              <div className="about-banner-text">
                <h1>QUALITY SET IN SURFACES</h1>
                <p>
                  Astra Surfaces- Your Premier Choice For Porcelain, Natural
                  Stone and Quartz Countertops In Dallas
                </p>
                {/* <Link to={"/contact-us"}><button>CONTACT US</button></Link> */}
              </div>
            </div>
            <div className=" pt-5">
              <div className="container">
                <p className="">
                  Astra Surfaces is more than just a wholesale distributor; we
                  are your collaborators in creating magnificent places with the
                  highest quality materials. Our objective is to deliver a
                  simple consumer experience for everyone. Whether you are an
                  experienced countertop installer, a creative interior
                  designer, a fastidious homeowner, or a visionary home builder,
                  Astra Surfaces is dedicated to meeting your demands with
                  professionalism, ethics, and a commitment to perfection.
                </p>

                <p>
                  <p className="">
                    At Astra Surfaces we make design dreams become reality, and
                    the beauty of Natural Stone, Variant Quartz, and Variant
                    Porcelain slabs elevates your projects. Explore our
                    extensive range, witness our unwavering commitment to
                    excellence, and let Astra Surfaces be your trusted partner
                    in creating magnificent, long-lasting settings. Fabricators,
                    designers, architects, builders, and homeowners are always
                    welcome to visit our slab galleries and warehouse to select
                    individual slabs for their projects.
                  </p>
                </p>
              </div>
            </div>

            <section className="section-10 gap">
              <div className="pb-5">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="text-wrapper">
                        <h3>Personally Curate your Environment</h3>
                        <p className="my-4">
                          Our showroom is open to the public. Stop by and
                          explore our vast inventory to find the material that
                          fits your needs.
                        </p>
                        <Link to={"/products"}>
                          <button>EXPLORE</button>
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-6 mt-lg-0 mt-3">
                      <div className="d-flex">
                        <img
                          src="assests/images/Home-Icon-01-1.png"
                          alt=""
                          className="icon"
                        />
                        <p className="my-auto">
                          Our knowledgeable and welcoming staff will assist you
                          in selecting the ideal slab.
                        </p>
                      </div>
                      <div className="d-flex">
                        <img
                          src="assests/images/Home-Icon-02-1.png"
                          alt=""
                          className="icon"
                        />
                        <p className="my-auto">
                          We will hold your slabs for 2 weeks once selected.
                        </p>
                      </div>
                      <div className="d-flex">
                        <img
                          src="assests/images/Home-Icon-03-1.png"
                          alt=""
                          className="icon"
                        />
                        <p className="my-auto">
                          After completing the purchase from Variant Surfaces,
                          your fabricator will collaborate with you to install
                          the slab.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="about-section-page">
              <div>
                <div className="py-5">
                  <section className="about-product-section">
                    <div className="container">
                      <h3 className="text-center">astra collection</h3>
                      <p className="text-center">
                        Explore distinctive charms and contemporary marvels
                        across our product lines
                      </p>
                      <div className="row mt-5">
                        <div className="row mt-md-5 mt-3 gx-3 justify-content-center">
                          {productsCategory &&
                            productsCategory.map((category) => {
                              let overlayText = "";
                              if (category.category === "Variant Quartz") {
                                overlayText =
                                  "Our Quartz slabs are Scratch, Stain and Heat resistance";
                              } else if (
                                category.category === "Variant Porcelain™"
                              ) {
                                overlayText =
                                  "Each colors has been selected with the great care to details to covey the Natural stone beauty to the porcelain surface";
                              } else if (
                                category.category === "Natural Stone"
                              ) {
                                overlayText =
                                  "Vast Range of Natural stones includes Granite, Marble, Quartzite, Soapstone and more";
                              }
                              return (
                                <div
                                  className="col-lg-4 col-md-4  mb-md-0 mb-3"
                                // data-aos="fade-up"
                                >
                                  <h4>
                                    {category.category}
                                    {category.category === "Variant Quartz" && (
                                      <sup
                                        className="registeredTrademark"
                                        style={{ left: "0" }}
                                      >
                                        ®
                                      </sup>
                                    )}
                                  </h4>
                                  <Link
                                    to={`/products/${category.category.replace(
                                      /\s+/g,
                                      "-"
                                    )}`}
                                    onClick={() => handleClick(category)}
                                  >
                                    <div className="image-wrapper">
                                      <img
                                        src={category.image}
                                        alt={category.category}
                                        className="image-1"
                                      />
                                      <div className="overlay">
                                        <p>{overlayText}</p>
                                      </div>
                                    </div>
                                  </Link>
                                  <Link
                                    to={`/products/${category.category.replace(
                                      /\s+/g,
                                      "-"
                                    )}`}
                                    onClick={() => handleClick(category)}
                                  >
                                    <button className="product-btn">
                                      View Product
                                    </button>
                                  </Link>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
            {/*
                        <section className='about-section-2'>
                            <div className="row justify-content-center">
                                <div className="col-11" >
                                    <h4>
                                        Astra carries a wide array of high-quality natural stone and engineered slabs from different parts of the world.
                                    </h4>
                                    <p>
                                        Astra Surfaces is one of the fastest-growing suppliers for natural and engineered surfaces in the United States. Astra’s name speaks for itself with our wide range of material to meet all your surface needs.
                                    </p>
                                    <p>
                                        Being in the stone industry for almost a decade with a stone processing factory, we have the privilege of handpicking the best material for our customers. Astra has a vast collection of stone to choose from: natural stone like Granite, Marble, Quartzite and engineered stone like Quartz. We bring in the best slabs from different parts of the world – Brazil, India, Italy, Spain, Vietnam, and more. We support a variety of customers ranging from architects, builders, fabricators, interior designers, and retailers for their surface needs.
                                    </p>
                                </div>
                            </div>
                        </section> */}

            <section className="about-cta-section">
              <div className="bg-image">
                <div className="row h-100 d-flex justify-content-center align-items-center">
                  <div className="col-10 d-flex justify-content-center text-center">
                    <div className="text-wrapper" data-aos="zoom-in">
                      <h3>Interested in Our Products?</h3>
                      <div className="mt-3">
                        <Link to={"/contact-us"}>
                          <button>CONTACT US</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </>
    </>
  );
};

export default About;
