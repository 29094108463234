import React, { useEffect, useState } from 'react'
import AOS from "aos";
import { Helmet } from "react-helmet";
import Loader from './Loader';
import Fancybox from './FancyBox';
import { FaSearch } from "react-icons/fa";
import { useScrollToTop } from './UserScrollTop';

const DesignGallery = () => {
    const [screenLoading, setScreenLoading] = useState(false);

    useEffect(() => {
        AOS.init({ duration: 1500 });
        AOS.refresh();
        setScreenLoading(false);
        setTimeout(() => {
            setScreenLoading(false);
        }, 3000);
    }, []);

    useScrollToTop()
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Astra Surfaces | Design Gallery</title>
            </Helmet>
            <>
                {screenLoading ? (
                    <Loader />
                ) :
                    <>
                        <section className='container design-gallery gap-1'>
                            <div className='row'>
                                <div className='col-12'>
                                    <section className="section-9">
                                        <div className="container">
                                            <h1>Featured Designs</h1>
                                            <Fancybox
                                                options={{
                                                    Carousel: {
                                                        infinite: false,
                                                    },
                                                }}
                                            >
                                                <div className="row my-md-5 my-3 gx-3">
                                                    <div className="col-xl-4 col-md-6">
                                                        <div className="image-wrapper">
                                                            <img src="assests/images/Granite--scaled.jpg" alt="Avatar" className="image" />
                                                            <div className="overlay">
                                                                <div className="text">
                                                                    <FaSearch
                                                                        data-fancybox="gallery"
                                                                        data-src="assests/images/Granite--scaled.jpg"
                                                                        className="button button--secondary"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-md-6 mt-md-0 mt-4">
                                                        <div className="image-wrapper">
                                                            <img src="assests/images/Antarctica-scaled.jpg" alt="Avatar" className="image" />
                                                            <div className="overlay">
                                                                <div className="text">
                                                                    <FaSearch
                                                                        data-fancybox="gallery"
                                                                        data-src="assests/images/Antarctica-scaled.jpg"
                                                                        className="button button--secondary"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-md-6 mt-xl-0 mt-4">
                                                        <div className="image-wrapper">
                                                            <img src="assests/images/BAGNO-AMAZONITE-scaled.jpg" alt="Avatar" className="image" />
                                                            <div className="overlay">
                                                                <div className="text">
                                                                    <FaSearch
                                                                        data-fancybox="gallery"
                                                                        data-src="assests/images/BAGNO-AMAZONITE-scaled.jpg"
                                                                        className="button button--secondary"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-md-6 mt-4">
                                                        <div className="image-wrapper">
                                                            <img src="assests/images/camino-panda-1.jpeg" alt="Avatar" className="image" />
                                                            <div className="overlay">
                                                                <div className="text">
                                                                    <FaSearch
                                                                        data-fancybox="gallery"
                                                                        data-src="assests/images/camino-panda-1.jpeg"
                                                                        className="button button--secondary"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-md-6 mt-4">
                                                        <div className="image-wrapper">
                                                            <img src="assests/images/nero marquina 001.webp" alt="Avatar" className="image" />
                                                            <div className="overlay">
                                                                <div className="text">
                                                                    <FaSearch
                                                                        data-fancybox="gallery"
                                                                        data-src="assests/images/nero marquina 001.webp"
                                                                        className="button button--secondary"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-md-6 mt-4">
                                                        <div className="image-wrapper">
                                                            <img src="assests/images/cristallo-rose-scaled.jpeg" alt="Avatar" className="image" />
                                                            <div className="overlay">
                                                                <div className="text">
                                                                    <FaSearch
                                                                        data-fancybox="gallery"
                                                                        data-src="assests/images/cristallo-rose-scaled.jpeg"
                                                                        className="button button--secondary"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-md-6 mt-4">
                                                        <div className="image-wrapper">
                                                            <img src="assests/images/lobby-patagonia-sodalite-scaled.jpg" alt="Avatar" className="image" />
                                                            <div className="overlay">
                                                                <div className="text">
                                                                    <FaSearch
                                                                        data-fancybox="gallery"
                                                                        data-src="assests/images/lobby-patagonia-sodalite-scaled.jpg"
                                                                        className="button button--secondary"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-md-6 mt-4">
                                                        <div className="image-wrapper">
                                                            <img src="assests/images/Macchia-antica-1-scaled.jpg" alt="Avatar" className="image" />
                                                            <div className="overlay">
                                                                <div className="text">
                                                                    <FaSearch
                                                                        data-fancybox="gallery"
                                                                        data-src="assests/images/Macchia-antica-1-scaled.jpg"
                                                                        className="button button--secondary"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-md-6 mt-4">
                                                        <div className="image-wrapper">
                                                            <img src="assests/images/parete-agatabl-scaled.jpg" alt="Avatar" className="image" />
                                                            <div className="overlay">
                                                                <div className="text">
                                                                    <FaSearch
                                                                        data-fancybox="gallery"
                                                                        data-src="assests/images/parete-agatabl-scaled.jpg"
                                                                        className="button button--secondary"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-md-6 mt-4">
                                                        <div className="image-wrapper">
                                                            <img src="assests/images/PARETE-SODALITE1-scaled.jpg" alt="Avatar" className="image" />
                                                            <div className="overlay">
                                                                <div className="text">
                                                                    <FaSearch
                                                                        data-fancybox="gallery"
                                                                        data-src="assests/images/PARETE-SODALITE1-scaled.jpg"
                                                                        className="button button--secondary"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-md-6 mt-4">
                                                        <div className="image-wrapper">
                                                            <img src="assests/images/marble-1-scaled.jpeg" alt="Avatar" className="image" />
                                                            <div className="overlay">
                                                                <div className="text">
                                                                    <FaSearch
                                                                        data-fancybox="gallery"
                                                                        data-src="assests/images/marble-1-scaled.jpeg"
                                                                        className="button button--secondary"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-md-6 mt-4">
                                                        <div className="image-wrapper">
                                                            <img src="assests/images/granite-scaled.jpg" alt="Avatar" className="image" />
                                                            <div className="overlay">
                                                                <div className="text">
                                                                    <FaSearch
                                                                        data-fancybox="gallery"
                                                                        data-src="assests/images/granite-scaled.jpg"
                                                                        className="button button--secondary"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Fancybox>
                                    
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </>
                }
            </>
        </>
    )
}

export default DesignGallery