import React, { useEffect, useState } from "react"
import CtaSection from "./Cta-section"
import AOS from "aos";
import Loader from "./Loader";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useProductContext } from "../context/CategoryContext";
import { useScrollToTop } from "./UserScrollTop";

const Products = () => {
    const [screenLoading, setScreenLoading] = useState(false);
    // const [tileCategories, setTileCategories] = useState([]);
    const { productsCategory, updateSelectedCategory } = useProductContext([]);
    const navigate = useNavigate()
    useEffect(() => {
        AOS.init({ duration: 1500 });
        AOS.refresh();
        setScreenLoading(false);
        setTimeout(() => {
            setScreenLoading(false);
        }, 3000);

    }, []);



    const handleClick = (category) => {
        updateSelectedCategory(category);
    };

    useScrollToTop()
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Countertops | products | quartz | marble | granite | quartzite </title>
                <meta name="description" content="Explore the vast range of surface products Astra Surfaces offers in New Jersey. Browse through the page to learn our latest quartz, marble, granite, and quartzite countertops in different designs, colors, textures, and more. Get your interior inspiration right here!" />
            </Helmet>

            <>
                {screenLoading ? (
                    <Loader />
                ) :
                    <>
                        <div className="product-banner">
                            <div className="row gx-0">
                                <div className="col-xl-7">
                                    <img src="assests/images/CRYSTALLO-0004.webp" alt="" />
                                </div>
                                <div className="col-xl-5">
                                    <div className="product-banner-text">
                                        <h1>Products</h1>
                                        <p>
                                            Our mission at Astra Surfaces is to give our customers the best slabs of engineered and natural
                                            stone. Our Variant Quartz and Variant Porcelain slabs come in a variety of hues and styles. Take
                                            a look at all of our products below.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <section className="products-section-3 py-5">
                            <div className="container mb-4">
                                <h3 className="text-center" style={{ "fontVariant": "small-caps" }}>ASTRA COLLECTIONS</h3>
                                <p className="text-center">
                                    Explore distinctive charms and contemporary marvels
                                    across our product lines
                                </p>
                                <div className="row mt-md-5 mt-3 gx-3 justify-content-center">
                                    {productsCategory &&
                                        productsCategory.map((category) => {
                                            let overlayText = "";
                                            if (category.category === "Variant Quartz") {
                                                overlayText = "Our Quartz slabs are Scratch, Stain and Heat resistance";
                                            } else if (category.category === "Variant Porcelain™") {
                                                overlayText = "Each colors has been selected with the great care to details to covey the Natural stone beauty to the porcelain surface";
                                            } else if (category.category === "Natural Stone") {
                                                overlayText = "Vast Range of Natural stones includes Granite, Marble, Quartzite, Soapstone and more";
                                            }
                                            return (
                                                <div
                                                    className="col-lg-4 col-md-4  mb-md-0 mb-3"
                                                // data-aos="fade-up"
                                                >
                                                    <h4>
                                                        {category.category}
                                                        {category.category === "Variant Quartz" && <sup className="registeredTrademark" style={{ "left": "0" }}>®</sup>}
                                                    </h4>
                                                    <Link
                                                        to={`/products/${category.category.replace(
                                                            /\s+/g,
                                                            "-"
                                                        )}`}
                                                        onClick={() => handleClick(category)}
                                                    >
                                                        <div
                                                            className="image-wrapper"
                                                        >
                                                            <img
                                                                src={category.image}
                                                                alt={category.category}
                                                                className="image-1"
                                                            />
                                                            <div className="overlay">
                                                                <p>{overlayText}</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <Link
                                                        to={`/products/${category.category.replace(
                                                            /\s+/g,
                                                            "-"
                                                        )}`}
                                                        onClick={() => handleClick(category)}
                                                    >
                                                        <button className="product-btn">
                                                            View Product
                                                        </button>
                                                    </Link>
                                                </div>
                                            );
                                        })}
                                </div>
                                {/* <h3 className="mt-4">From enduring classics to cutting-edge contemporary countertops</h3>
                                <p>
                                    At Astra Surfaces, we offer countertops in New Jersey that blend technical sophistication and artistic finesse that make every kitchen, bathroom, and outdoor space look royal and elegant. Every stone, design, color, and finish ensures your space remains as beautiful, durable, and magnificent as ever. This is what makes Astra Surfaces a reliable countertop supplier in New Jersey.
                                </p> */}
                            </div>
                        </section>

                        <section className='products-cta-section'>
                            <div className="bg-image">
                                <div className="row h-100 d-flex justify-content-center align-items-center">
                                    <div className="col-10 d-flex justify-content-center text-center">
                                        <div className='text-wrapper' data-aos="zoom-in">
                                            <h3>Need Help Finding The Right Product?</h3>
                                            <p>
                                                Explore our products by category, color, finish, and thickness
                                            </p>
                                            <div>
                                                <Link to={"/contact-us"}><button>CONTACT US</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                }
            </>
        </>
    )
}

export default Products