
// ProductContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const ProductContext = createContext();

export const useProductContext = () => useContext(ProductContext);

export const ProductProvider = ({ children }) => {
    const [productsCategory, setProductsCategory] = useState([]);
    const [selectedCategory,setSelectedCategory] = useState([])
    const [selectedCategoryProducts, setSelectedCategoryProducts] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://admin.astrasurfaces.com/category/', {
                method: 'GET',
                mode: 'cors',
                headers: {
                    Authorization: 'Basic ' + btoa(`${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`),
                },
            });

           
            // Access the data from the response
            const data = response.data;
            setProductsCategory(data);
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    useEffect(() => {
        // Retrieve data from local storage on component mount
        fetchData()

        const storedProducts = localStorage.getItem('selectedCategoryProducts');
        if (storedProducts) {
            setSelectedCategoryProducts(JSON.parse(storedProducts));
        }
        const storedCategory = localStorage.getItem('selectedCategory');
        if (storedCategory) {
            setSelectedCategory(JSON.parse(storedCategory));
        }
    }, []);

    const fetchProductsByCategory = async (category) => {
        try {
            const response = await axios.get(`https://admin.astrasurfaces.com/products/${category}/`, {
                headers: {
                    Authorization: 'Basic ' + btoa(`${process.env.REACT_APP_API_USERNAME}:${process.env.REACT_APP_API_PASSWORD}`),
                },
            });
            const data = response.data;

            setSelectedCategoryProducts(data);
            // Store data in local storage
            localStorage.setItem('selectedCategoryProducts', JSON.stringify(data));
        } catch (error) {
            console.error('Error fetching products by category:', error.message);
            return [];
        }
    };

    const updateSelectedCategory =(category)=>{
        setSelectedCategory(category)
        localStorage.setItem('selectedCategory',JSON.stringify(category));
    }

    
    return (
        <ProductContext.Provider value={{ selectedCategoryProducts, fetchProductsByCategory, productsCategory,updateSelectedCategory,selectedCategory }}>
            {children}
        </ProductContext.Provider>
    );
};
