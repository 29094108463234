import React from 'react'
import { AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai";
import { BiLogoLinkedin, BiLogoFacebook } from "react-icons/bi";
import './Footer.css'
import { Link } from 'react-router-dom';
import { useProductContext } from '../context/CategoryContext';

const Footer = () => {
    const { updateSelectedCategory, productsCategory,fetchProductsByCategory } = useProductContext();

    const handleClick = async (category) => {
        await fetchProductsByCategory(category.category_url);
        updateSelectedCategory(category)
      }
    return (
        <footer>
            <div className="bg-color">
                <div className="container">
                    <div className="row gx-0">
                        <div className="col-lg-4 h-auto">
                            <div className=''>
                                <img src="/Astra_White logo.png" alt="" className='footer-logo' />
                                <address>
                                    1580 Lower Rd Suite B, Linden, NJ 07036, United States
                                </address>
                                <a href='tel:+19089889901'>+1 908-988-9902</a>
                                <span>|</span>
                                <a href='mailto:info@astrasurfaces.com'>info@astrasurfaces.com</a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-6 h-auto pages">
                            <div className="">
                                <h4>Resources</h4>
                                <ul className='mt-4'>
                                    <li><Link to={'/catalogs'}>Catalogs</Link></li>
                                    <li><Link to={'/faq'}>FAQ</Link></li>
                                    <li><Link to={'/warranty-registration'}>Warranty</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-6 pages-2 h-auto">
                            <div className="my-auto align-items-center ">
                                <h4>Products</h4>
                                <ul className='mt-4'>
                                    {productsCategory.map((category) => (
                                        <li key={category.id}>
                                            <Link to={`/products/${category.category.replace(/\s+/g, '-')}`} onClick={() => handleClick(category)}>
                                                {category.category}
                                                {category.category === "Variant Quartz" && <sup className="registeredTrademark" style={{"left":"0"}}>®</sup>}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-6 pages-2 h-auto">
                            <h4>Inspiration</h4>
                            <ul className='mt-4'>
                                <li><Link to={'/blogs'}>Blogs</Link></li>
                                <li><Link to={'/design-gallery'}>Design Gallery</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-6 pages-2 h-auto">
                            <Link to={'/about-us'}><h4 >About Us</h4></Link>
                            <Link to={'/contact-us'}><h4 className='mt-3' style={{"textTransform":"capitalize"}}>Contact</h4></Link>
                        </div>
                       
                        <div className="col-12">
                            <div className="social-icons ms-3 text-center">
                                <a href="https://www.instagram.com/astrasurfaces/" target="_blank"><AiOutlineInstagram className='icon' /></a>
                                <a href="https://twitter.com/variantsurfaces?s=21&t=f2N_G4fRtEPt9qJnMEmXew" target='_blank'><AiOutlineTwitter className='icon' /></a>
                                <a href="https://www.linkedin.com/in/astra-surfaces-6a1467296/" target='_blank'><BiLogoLinkedin className='icon' /></a>
                                <a href="https://www.facebook.com/astrasurfaces/" target='_blank'><BiLogoFacebook className='icon' /></a>
                            </div>
                            <div className='privacy-para my-4'>
                                <span>Copyright@astrasurfaces2023</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer