import React from 'react'
import { Link } from 'react-router-dom'

const CtaSection = () => {
    return (
        <section className='cta-section'>
            <div className="bg-image">
                <div className="row h-100 d-flex justify-content-center align-items-center">
                    <div className="col-10 d-flex justify-content-center text-center">
                        <div className='text-wrapper' data-aos="zoom-in">
                            <h3>Need Help Finding The Right Product?</h3>
                            <p>
                            Contact us to find out which of our surfaces best suits your requirements.
                            </p>
                            <div>
                                {/* <a href=''>+1 908-988-9902</a>
                                <span>or</span> */}
                                <Link to={"/contact-us"}><button>CONTACT US</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CtaSection